import { gql } from '@apollo/client';
import { LatLngTuple } from 'leaflet';

export const SEARCH_SYMBOLS = 2;
export const RECENTS_AMOUNT = 5;
export const MAX_LEGS = 5;
export const MAX_AUTOCOMPLETE = 20;
export const MAX_WAIT_TIME = 90;
export const MIN_WALKING_TIME = 180;
export const MAX_WALK_DISTANCE = 2500;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const TRANSPORT_ARRIVAL_RANGE = 1800; // 30 min in seconds
export const NUMBER_OF_DEPARTURES = 30;
export const TIMETABLE_NUMBER_OF_DEPARTURES = 300;
// According to the requirements, the center of the map should be following address:
// `Miskevycha Square, 10 Lviv, Lviv Oblast`
export const MAP_CENTER: LatLngTuple = [49.8397231, 24.0302049];

export const GET_ROUTES_QUERY = gql`
  query Routes(
    $fromPlace: String
    $toPlace: String
    $date: String
    $time: String
    $maxWalkDistance: Float!
    $numItineraries: Int!
    $wheelchair: Boolean
    $arriveBy: Boolean
  ) {
    plan(
      fromPlace: $fromPlace
      toPlace: $toPlace
      date: $date
      time: $time
      maxWalkDistance: $maxWalkDistance
      numItineraries: $numItineraries
      wheelchair: $wheelchair
      arriveBy: $arriveBy
    ) {
      itineraries {
        startTime
        endTime
        duration
        walkTime
        legs {
          startTime
          endTime
          mode
          duration
          distance
          route {
            gtfsId
            shortName
            textColor
          }
          legGeometry {
            points
          }
          from {
            lat
            lon
            name
            stop {
              gtfsId
              alerts {
                alertCause
              }
            }
          }
          to {
            lat
            lon
            name
            stop {
              gtfsId
              alerts {
                alertCause
              }
            }
          }
          intermediatePlaces {
            stop {
              lat
              lon
              name
              code
              alerts {
                alertCause
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_STOP_QUERY = gql`
  query Stop($id: String!, $numberOfDepartures: Int, $timeRange: Int) {
    stop(id: $id) {
      stoptimesWithoutPatterns(
        numberOfDepartures: $numberOfDepartures
        timeRange: $timeRange
      ) {
        realtimeArrival
        serviceDay
        realtime
        trip {
          gtfsId
          pattern {
            code
          }
          route {
            id
            textColor
            shortName
            mode
            gtfsId
          }
        }
      }
    }
  }
`;

export const GET_ROUTE_GEOMETRY = gql`
  query Route($ids: [String]) {
    routes(ids: $ids) {
      gtfsId
      patterns {
        stops {
          lat
          lon
        }
        code
        directionId
        patternGeometry {
          points
        }
      }
    }
  }
`;
